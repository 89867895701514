import React, { useState, useEffect, SyntheticEvent, useRef } from "react";
import AdminLayout from "../layout/AdminLayout";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  Alert,
  Tabs,
  Tab,
  IconButton
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/tr";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import AdminField from "../layout/AdminField";
import {
  initializeNewCampaign,
  initializeCampaignData,
} from "../utils/initializeCampaign";
import { v4 as uuid } from "uuid";
import agent from "../api/agent";
import { toast } from "react-toastify";
import ImagePickerModal from "../layout/ImagePickerModal";
import Swal from "sweetalert2";
import { calculateProbabilities } from "../utils/calculateProbabilities";
import { Slice } from '../models/slice';

export default function CampaignDetails() {
  const { id } = useParams<{ id: string }>();
  const [campaign, setCampaign] = useState<any | null>(null);
  const [titleInput, setTitleInput] = useState<string>("");
  const [tabValue, setTabValue] = useState(0);
  const [errors, setErrors] = useState<any>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [activeField, setActiveField] = useState<string | null>(null);

  const [iframeSrc, setIframeSrc] = useState<string | null>(null);
  const [iframeSize, setIframeSize] = useState({ width: 360, height: 640 });
  const [isLoaded, setIsLoaded] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [submitType, setSubmitType] = useState<string | null>(null);

  const formRef = useRef<HTMLFormElement>(null);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      agent.Campaigns.details(id).then((response) => {
        const campaignData: any = response;
        campaignData.wheel.slices.sort(
          (a: any, b: any) => a.listOrder - b.listOrder
        );
        setCampaign(initializeCampaignData(campaignData));
        setTitleInput(campaignData.title);        
      });
    } else {
      const newCampaign = initializeNewCampaign();
      newCampaign.wheel.slices.forEach((slice, index) => {
        slice.listOrder = index + 1;
      });
      setCampaign(newCampaign);
      setTitleInput(newCampaign.title);
    }
  }, [id]);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  }, [submitType]);

  const handleTabChange = (event: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const handleSliceAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newSliceAmount = parseInt(event.target.value);
    const currentSliceAmount = campaign?.wheel.slices.length || 6;

    if (newSliceAmount > currentSliceAmount) {
      const newSlices = Array(newSliceAmount - currentSliceAmount)
        .fill(null)
        .map((_, index) => ({
          id: uuid(),
          prizeValue: "",
          prizeImage: "",
          wedgeImage: "",
          isPrize: true,
          probability: null,
          stock: null,
          initialStock: null,
          stockControl: false,
          stockDropped: false,
          listOrder: currentSliceAmount + index + 1,
          wheelId: campaign?.wheel.Id || null,
        }));
      setCampaign({
        ...campaign!,
        wheel: {
          ...campaign!.wheel,
          sliceAmount: newSliceAmount,
          slices: [...campaign!.wheel.slices, ...newSlices],
        },
      });
    } else {
      setCampaign({
        ...campaign!,
        wheel: {
          ...campaign!.wheel,
          sliceAmount: newSliceAmount,
          slices: campaign!.wheel.slices.slice(0, newSliceAmount),
        },
      });
    }
  };

  const handleSliceChange = (
    index: number,
    field: string,
    value: string | boolean | number | null
  ) => {
    if (!campaign) return;

    setIsDirty(false);

    const newSlices = [...campaign.wheel.slices];
    newSlices[index] = { ...newSlices[index], [field]: value };
    setCampaign({
      ...campaign,
      wheel: { ...campaign.wheel, slices: newSlices },
    });
  };

  const handleFieldChange = (field: string, value: any) => {
    setIsDirty(false);
    setCampaign({ ...campaign, [field]: value });
  };

  const handleWheelChange = (field: string, value: any) => {
    if (!campaign) return;

    setIsDirty(false);

    setCampaign({
      ...campaign,
      wheel: { ...campaign.wheel, [field]: value },
    });
  };

  const handleFormStateChange = (field: string, value: any) => {
    if (!campaign) return;

    setIsDirty(false);

    setCampaign({
      ...campaign,
      formState: { ...campaign.formState, [field]: value },
    });
  };

  const validateForm = () => {
    let tempErrors: any = {};

    if (!titleInput) tempErrors.titleInput = "Kampanya Başlığı zorunludur";
    if (campaign?.wheel.slices.some((slice: any) => !slice.prizeValue)) {
      tempErrors.slices = "Ödül değeri çark dilimi için zorunludur.";
    }
    if (
      campaign?.wheel.slices.some(
        (slice: any) => slice.probability! > 100 && slice.probability! < 0
      )
    ) {
      tempErrors.slices = "Olasılık 0'dan küçük ve 100'den büyük olamaz.";
    }

    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (isDirty) {
      // If there are no changes, don't resubmit or show error
      if (submitType === "regularSubmit")  {
        navigate("/campaigns", { state: { reload: true } });
      }
      return;
    }

    const campaignId = campaign?.id || uuid();
    const wheelId = campaign?.wheel.id || uuid();

    const campaignData = {
      ...campaign,
      id: campaignId,
      title: titleInput,
      startDate: (campaign!.startDate as Dayjs).toISOString(),
      endDate: (campaign!.endDate as Dayjs).toISOString(),
      collectUserData: campaign!.collectUserData === 1 ? 1 : (campaign!.collectUserData === 2 ? 2 : 3),
      wheel: {
        ...campaign!.wheel,
        id: wheelId,
        campaignId: campaignId,
        spinSpeed: campaign!.wheel.spinSpeed === 1 ? 1 : 2,
        stockControlRate: campaign!.wheel.stockControlRate ? campaign!.wheel.stockControlRate : null,
        probabilityDropRate: campaign!.wheel.probabilityDropRate ? campaign!.wheel.probabilityDropRate : null,
        slices: campaign!.wheel.slices.map((slice: any, index: any) => ({
          ...slice,
          id: slice.id || uuid(),
          wheelId: wheelId,
          probability: slice.probability !== null ? slice.probability : null,
          stock: slice.stock !== null ? slice.stock : null,
          listOrder: index + 1,
        })),
      },
    };

    try {
      if (id) {
        await agent.Campaigns.update(campaignData);
      } else {
        await agent.Campaigns.create(campaignData);
      }      
      if (submitType === "regularSubmit") {
        navigate("/campaigns", { state: { reload: true } });
      } else {
        setIsDirty(true);
      }
    } catch (error: any) {
      if (error.response) {
        console.error("Error response data:", error.response.data);
      } else {
        console.error("Kampanya düzenlenirken bir hata oluştu:", error.message);
      }
    }
    setSubmitType(null);
  };

  const handleShowGameScreen = async () => {
    if (!validateForm()) {
      toast.error(
        "Lütfen geri dönüp kırmızıyla işaretlenen zorunlu alanları doldurunuz!"
      );
      return;
    }

    setSubmitType("showGameScreen");
    
    if (!isLoaded) {
      setIframeSrc(`/game/${campaign.id}`);
      setIsLoaded(true);
    } else {
      setIframeSrc(null);
      setTimeout(() => setIframeSrc(`/game/${campaign.id}`), 100);
    }
  };

  const handleRegularSubmit = async () => {
    if (!validateForm()) {
      toast.error(
        "Lütfen geri dönüp kırmızıyla işaretlenen zorunlu alanları doldurunuz!"
      );
      return;
    }

    setSubmitType("regularSubmit");
  };

  const openImagePicker = (field: string) => {
    setActiveField(field);
    setOpenModal(true);
  };

  const handleImageSelect = (url: string) => {
    if (!campaign || !activeField) return;

    if (activeField.startsWith("slice")) {
      const [_, indexStr, sliceField] = activeField.split("-");
      const index = parseInt(indexStr, 10);
      handleSliceChange(index, sliceField, url);
    } else if (activeField === "modalBg") {
      setCampaign({ ...campaign, modalBg: url });
    } else {
      handleWheelChange(activeField, url);
    }

    setOpenModal(false);
  };

  const moveSlice = (index: number, direction: "up" | "down") => {
    if (!campaign) return;

    const newSlices = [...campaign.wheel.slices];
    const sliceToMove = newSlices[index];
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    // Swap slices
    newSlices[index] = newSlices[targetIndex];
    newSlices[targetIndex] = sliceToMove;

    // Update listOrder
    newSlices[index].listOrder = index + 1;
    newSlices[targetIndex].listOrder = targetIndex + 1;

    setCampaign({
      ...campaign,
      wheel: { ...campaign.wheel, slices: newSlices },
    });
  };

  const handleCalculateProbabilities = () => {
    const stockValues = campaign.wheel.slices
      .filter((slice: Slice) => slice.stock !== null) // Only consider slices with stock values
      .map((slice: Slice) => slice.stock!); // Get the stock values
  
    const probabilities = calculateProbabilities(stockValues);
  
    const updatedSlices = campaign.wheel.slices.map((slice: Slice, index: number) => {
      if (slice.stock !== null) {
        return { ...slice, probability: (probabilities[index] / 100).toFixed(3) }; // Convert to 3 digits format for summed to 1
      }
      return slice;
    });
  
    setCampaign({
      ...campaign,
      wheel: { ...campaign.wheel, slices: updatedSlices },
    });
  };

  const handleDeleteGameData = () => {
    Swal.fire({
      title: "Silmek istediğinizden emin misiniz?",
      text: "Bu kampanya için kaydedilmiş tüm oyun ve oyuncu verileri silinecektir. Silme işlemini geri alamazsınız!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Tamam",
      cancelButtonText: "İptal",
    }).then((result) => {
      if (result.isConfirmed) {
        agent.Players.deleteAll(id)
          .then(() => {
            toast.success("Tüm oyun ve oyuncu verileri silindi!");
          })
          .catch((error) => {
            toast.error("Veriler silinirken bir hata oluştu.");
          });
      }
    });
  };
  
  const handleResetStocks = () => {
    agent.Slices.resetStocks(campaign.wheel.id)
      .then(() => {
        const updatedSlices = campaign.wheel.slices.map((slice: Slice, index: number) => {
          if (slice.stock !== null) {
            return { ...slice, stock: slice.initialStock, stockDropped: false };
          }
          return slice;
        });
        setCampaign({
          ...campaign,
          wheel: { ...campaign.wheel, slices: updatedSlices },
        });
        toast.success("Dilim stoklarını başlangıç değerine alma işlemiyle birlikte veriler güncellendi!");
      })
      .catch((error) => {
        toast.error("Dilim stokları başlangıç değerlerine alınırken bir hata oluştu.");
      });
  }

  const handleSizeChange = (size: string) => {
    if (size === '9:16') {
      setIframeSize({ width: 360, height: 640 });
    } else if (size === '16:9') {
      setIframeSize({ width: 640, height: 360 });
    }
  };

  if (!campaign) return null;

  return (
    <AdminLayout title={campaign.title || "Kampanya Ekle"}>
      <Button
        variant="outlined"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/campaigns")}
        sx={{ mr: 3 }}
      >
        Kampanyalara Dön
      </Button>
      {id && (
        <>
          <Button
            variant="contained"
            startIcon={<AnalyticsIcon />}
            color="success"
            onClick={() => navigate(`/statistics/${id}`)}
            sx={{ mr: 2 }}
          >
            İstatistikler
          </Button>
          <Button
            variant="contained"
            startIcon={<VideogameAssetIcon />}
            color="warning"
            onClick={() => window.open(`/game/${id}`, "_blank")}
            sx={{ mr: 2 }}
          >
            Oyun Ekranı
          </Button>
          <Button
            variant="contained"
            startIcon={<DeleteIcon />}
            color="error"
            onClick={handleDeleteGameData}
          >
            Oyun ve Oyuncu Verilerini Sil
          </Button>
        </>
      )}
      {campaign.id ? (
        <Alert sx={{ my: 3 }} severity="warning">
          Kampanyanızı oluşturdunuz. Artık <strong>Çark Dilim Alanları</strong>{" "}
          sekmesindeki <strong>Dilim Sayısı</strong> oluşturulmuş kampanya
          içinde değiştirilemez.
        </Alert>
      ) : (
        <Alert sx={{ my: 3 }} severity="info">
          Temel kampanya bilgilerinizi girdikten sonra kaydetmeden önce lütfen
          yan sekmelerdeki bilgileri de düzenleyiniz. Dilerseniz{" "}
          <strong>Dilim Sayısı</strong> hariç bilgilerinizi sonradan da
          düzenleyebilirsiniz. <strong>Çark Dilim Alanları</strong>nda yer alan{" "}
          <strong>Dilim Sayısı</strong> kampanya oluşturulduktan sonra
          değiştirilemez. Kampanyalarınızı oluşturduktan sonra ödül
          istatistiklerini inceleyebileceğiniz <strong>İstatistikler</strong>{" "}
          butonu hemen yukarıda yer alacaktır.
        </Alert>
      )}
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="basic tabs example"
      >
        <Tab
          label="Kampanya Alanları"
          id="simple-tab-0"
          aria-controls="simple-tabpanel-0"
        />
        <Tab
          label="Çarkıfelek Alanları"
          id="simple-tab-1"
          aria-controls="simple-tabpanel-1"
        />
        <Tab
          label="Çark Görselleştirme"
          id="simple-tab-3"
          aria-controls="simple-tabpanel-2"
        />
        <Tab
          label="Çark Dilim Alanları"
          id="simple-tab-3"
          aria-controls="simple-tabpanel-3"
        />
        <Tab
          label="Stok Düşme Kontrolü"
          id="simple-tab-4"
          aria-controls="simple-tabpanel-4"
        />
        <Tab
          label="Form Özelleştirme"
          id="simple-tab-5"
          aria-controls="simple-tabpanel-5"
        />
      </Tabs>
      <Divider sx={{ mb: 5 }} />
      <form ref={formRef} onSubmit={handleSubmit}>
        <div
          role="tabpanel"
          hidden={tabValue !== 0}
          id="simple-tabpanel-0"
          aria-labelledby="simple-tab-0"
        >
          <Grid container spacing={4}>
            <AdminField
              label="Kampanya Başlığı"
              value={titleInput}
              onChange={(value) => setTitleInput(value)}
              error={!!errors.titleInput}
              errorMessage={errors.titleInput}
            />
            <AdminField
              label="Başlangıç Tarihi"
              type="date"
              value={campaign.startDate}
              onChange={(newValue) =>
                handleFieldChange("startDate", newValue || dayjs())
              }
              error={!!errors.startDate}
              errorMessage={errors.startDate}
            />
            <AdminField
              label="Bitiş Tarihi"
              type="date"
              value={campaign.endDate}
              onChange={(newValue) =>
                handleFieldChange("endDate", newValue || dayjs())
              }
              error={!!errors.endDate}
              errorMessage={errors.endDate}
            />
            <AdminField
              label="Yayında mı?"
              type="switch"
              value={campaign.isActive}
              onChange={(value) =>
                handleFieldChange("isActive", value)
              }
            />
            <AdminField
              label="Kullanıcı kaydı"
              type="radio"
              value={campaign.collectUserData}
              helperText="Oyun başında veya sonunda kullanıcı bilgileri ile kayıt almak istiyorsanız bu ayarı düzenleyiniz"
              onChange={(value) =>
                handleFieldChange("collectUserData", parseInt(value))
              }
              selectOptions={[
                { label: "Kayıt Alma", value: 1 },
                { label: "Oyun Başında", value: 2 },
                { label: "Oyun Sonunda", value: 3 },
              ]}
            />
          </Grid>

          <Divider sx={{ mb: 5 }} />
          <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
            Ek Bilgi Alanları
          </Typography>

          <Grid container spacing={4} sx={{ mb: 4 }}>
            <AdminField
              label="Açıklama"
              value={campaign.description}
              helperText="Etkinliğin açıklama metnini yazınız"
              onChange={(value) =>
                handleFieldChange("description", value)
              }
            />
            <AdminField
              label="Firma Adı"
              value={campaign.companyName}
              helperText="Etkinliğin yapılacağı firmanın adını yazınız"
              onChange={(value) =>
                handleFieldChange("companyName", value)
              }
            />
            <AdminField
              label="Etkinlik Yeri"
              value={campaign.venue}
              helperText="Etkinliğin yapılacağı yeri yazınız"
              onChange={(value) => handleFieldChange("venue", value)}
            />
          </Grid>

          <Divider sx={{ mb: 5 }} />
          <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
            Özelleştirme
          </Typography>
          
          <Grid container spacing={4} sx={{ mb: 4 }}>
            <AdminField
              label="Ödül Yazısı"
              value={campaign.modalText}
              onChange={(value) => handleFieldChange("modalText", value)}
              helperText="Ödül popupı üzerindeki yazıyı değiştirmek istiyorsanız buradan düzenleyebilirsiniz."
            />
            <AdminField
              label="Ödül Popup Arkaplan"
              value={campaign.modalBg}
              onChange={(value) => handleFieldChange("modalBg", value)}
              helperText="Ödül popupına özel arkaplan kullanmak için buradan ekleyiniz"
              type="button"
              buttonText="Galeri'den Seç"
              onClick={() => openImagePicker("modalBg")}
            />
            <AdminField
              label="Özel CSS"
              value={campaign.customCSS}
              helperText="Eğer oyun ekranında sadece bu kampanya için geçerli olacak görselleştirme uygulayacaksanız bu alan içinde özel CSS kullanınız."
              onChange={(value) => handleFieldChange("customCSS", value)}
              multiline
              rows={10}
            />
          </Grid>
        </div>
        {campaign.wheel && (
          <>
            <div
              role="tabpanel"
              hidden={tabValue !== 1}
              id="simple-tabpanel-1"
              aria-labelledby="simple-tab-1"
            >
              <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
                Çarkıfelek Ekranı Ayarları
              </Typography>
              <Grid container spacing={4} sx={{ mb: 4 }}>
                <AdminField
                  label="Ekran Başlığı"
                  value={campaign.wheel.pageTitle}
                  onChange={(value) => handleWheelChange("pageTitle", value)}
                  helperText="Çarkıfelek ekranında yer alacak başlığı yazınız"
                />
                <AdminField
                  label="Ekran Mottosu"
                  value={campaign.wheel.motto}
                  onChange={(value) => handleWheelChange("motto", value)}
                  helperText="Çarkıfelek ekranı çark altında yer alacak motto yazısını yazınız"
                />
                <AdminField
                  label="Ekran Logosu"
                  value={campaign.wheel.logo}
                  onChange={(value) => handleWheelChange("logo", value)}
                  helperText="Çarkıfelek ekranı çark üstünde yer alacak logoyu ekleyiniz"
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("logo")}
                />
                <AdminField
                  label="Ekran Arkaplan Görseli"
                  value={campaign.wheel.screenBg}
                  onChange={(value) => handleWheelChange("screenBg", value)}
                  helperText="Çarkıfelek ekranı arkaplan desen görselini yükleyiniz"
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("screenBg")}
                />
                <AdminField
                  label="Çark Altı Ek Görseli"
                  value={campaign.wheel.screenCenterBg}
                  onChange={(value) =>
                    handleWheelChange("screenCenterBg", value)
                  }
                  helperText="Çarkın altında ortada yer alacak ek görseli yükleyiniz"
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("screenCenterBg")}
                />
                <AdminField
                  label="Ekran Arkaplan Rengi"
                  type="color"
                  inline
                  inlineFix={1}
                  value={campaign.wheel.screenColor}
                  onChange={(value) => handleWheelChange("screenColor", value)}
                  helperText="Çarkıfelek ekranı arkaplan rengini seçiniz"
                />
                <AdminField
                  label="Ekran Yazı Rengi"
                  type="color"
                  inline
                  value={campaign.wheel.textColor}
                  onChange={(value) => handleWheelChange("textColor", value)}
                  helperText="Çarkıfelek ekranı yazı rengini seçiniz"
                />
              </Grid>
              <Divider sx={{ mb: 5 }} />
              <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
                Çark Ayarları
              </Typography>
              <Grid container spacing={4} sx={{ mb: 4 }}>
                <AdminField
                  label="Çark Pin Görseli"
                  value={campaign.wheel.wheelPinImage}
                  onChange={(value) =>
                    handleWheelChange("wheelPinImage", value)
                  }
                  helperText="Çark pinini ayrı görsel olarak kullanacaksanız buradan ekleyiniz"
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("wheelPinImage")}
                />
                <AdminField
                  label="Çark Kaplama Görseli"
                  value={campaign.wheel.spinnerCover}
                  onChange={(value) => handleWheelChange("spinnerCover", value)}
                  helperText="Çark kaplaması kullanıyorsanız buradan yükleyiniz. Dilim başlıkları ve dilim görselleri kaplama üzerinde kalacaktır."
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("spinnerCover")}
                />
                <AdminField
                  label="Ödül İsimlerini Göster"
                  type="switch"
                  value={campaign.wheel.showPrizeValues}
                  onChange={(value) =>
                    handleWheelChange("showPrizeValues", value)
                  }
                  helperText="Çark dekoru veya tam ekran çark kaplaması kullanılması durumunda dilimlerde ödüllerin isimlerinin gösterilmesini buradan ayarlayabilirsiniz. Ödül görselleri bu ayardan etkilenmeyecektir."
                />
                <AdminField
                  label="Çark Ortası Görseli"
                  value={campaign.wheel.circleImage}
                  onChange={(value) => handleWheelChange("circleImage", value)}
                  helperText="Çarkın ortasında yer alacak logo görselini yükleyiniz"
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("circleImage")}
                />
                <AdminField
                  label="Çark Dekoru"
                  type="switch"
                  value={campaign.wheel.spinnerDecoration}
                  onChange={(value) =>
                    handleWheelChange("spinnerDecoration", value)
                  }
                  helperText="Çarkın etrafındaki dekoru buradan açıp kapatabilirsiniz"
                />
                <AdminField
                  label="Çark Dekoru Görseli"
                  value={campaign.wheel.spinnerImage}
                  onChange={(value) => handleWheelChange("spinnerImage", value)}
                  helperText="Çark dekoru kullanıyorsanız standart görseli değiştirmek için buradan yükleyiniz"
                  type="button"
                  buttonText="Galeri'den Seç"
                  onClick={() => openImagePicker("spinnerImage")}
                />                
                <AdminField
                  label="Çark Efekti"
                  type="switch"
                  value={campaign.wheel.spinnerEffect}
                  onChange={(value) =>
                    handleWheelChange("spinnerEffect", value)
                  }
                  helperText="Çarkın parlama efektini buradan açıp kapatabilirsiniz"
                />
                <AdminField
                  label="Saat Yönünde Dönüş"
                  type="switch"
                  value={campaign.wheel.clockwise}
                  onChange={(value) => handleWheelChange("clockwise", value)}
                  helperText="Çarkın saat yönünde veya tersinde dönüşünü buradan ayarlayabilirsiniz"
                />
                <AdminField
                  label="Dönüş Süresi"
                  value={campaign.wheel.spinDuration}
                  type="number"
                  onChange={(value) => handleWheelChange("spinDuration", value)}
                  helperText="Çarkın kaç saniye döneceğini rakam olarak giriniz"
                />
                <AdminField
                  label="Dönüş Hızı"
                  type="radio"
                  value={campaign.wheel.spinSpeed}
                  onChange={(value) =>
                    handleWheelChange("spinSpeed", parseInt(value))
                  }
                  helperText="Çarkın dönüş hızını ayarlayınız."
                  selectOptions={[
                    { label: "Yavaş", value: 1 },
                    { label: "Hızlı", value: 2 },
                  ]}
                />
              </Grid>
            </div>
            <div
              role="tabpanel"
              hidden={tabValue !== 2}
              id="simple-tabpanel-2"
              aria-labelledby="simple-tab-2"
            >
              <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
                Çark Görselleştirme Ayarları
              </Typography>
              <Grid container spacing={6} sx={{ mb: 4 }}>
                <Grid item xs={6}>
                  <Grid container spacing={4} sx={{ mb: 4 }}>
                    <AdminField
                      label="Ekran Logosu Üstten Boşluk"
                      value={campaign.wheel.logoFromTop}
                      type="number"
                      onChange={(value) => handleWheelChange("logoFromTop", value !== "" ? parseInt(value) : null)}
                      helperText="Ekran logosunun üstten boşluk değerini rakam olarak giriniz"
                    />
                    <AdminField
                      label="Ekran Logosu Yüksekliği"
                      value={campaign.wheel.logoHeight}
                      type="number"
                      onChange={(value) => handleWheelChange("logoHeight", value !== "" ? parseInt(value) : null)}
                      helperText="Ekran logosunun yükseklik değerini rakam olarak giriniz. Genişliği orantılı olarak ayarlanacaktır."
                    />
                    <AdminField
                      label="Ekran Başlığı Üstten Boşluk"
                      value={campaign.wheel.titleFromTop}
                      type="number"
                      onChange={(value) => handleWheelChange("titleFromTop", value !== "" ? parseInt(value) : null)}
                      helperText="Ekran başlığının üstten boşluk değerini rakam olarak giriniz"
                    />
                    <AdminField
                      label="Ekran Başlığı Genişliği"
                      value={campaign.wheel.titleWidth}
                      type="number"
                      onChange={(value) => handleWheelChange("titleWidth", value !== "" ? parseInt(value) : null)}
                      helperText="Ekran başlığının genişliğini yüzde değeri olarak giriniz"
                    />
                    <AdminField
                      label="Ekran Başlığı Font Boyutu"
                      value={campaign.wheel.titleFontSize}
                      type="number"
                      onChange={(value) => handleWheelChange("titleFontSize", value !== "" ? parseInt(value) : null)}
                      helperText="Ekran başlığının punto boyutunu rakam olarak giriniz"
                    />                
                    <AdminField
                      label="Çark Üstten Boşluk"
                      value={campaign.wheel.wheelFromTop}
                      type="number"
                      onChange={(value) => handleWheelChange("wheelFromTop", value !== "" ? parseInt(value) : null)}
                      helperText="Çarkın üstten boşluk değerini yüzde değeri olarak 100'ü geçmeyecek şekilde giriniz"
                    />
                    <AdminField
                      label="Çark Genişliği"
                      value={campaign.wheel.wheelDiameter}
                      type="number"
                      onChange={(value) => handleWheelChange("wheelDiameter", value !== "" ? parseFloat(value.toFixed(1)) : null)}
                      helperText="Çarkın genişliğini rakam olarak giriniz. Eni ve boyu eşit olarak ayarlanacaktır."
                    />
                    <AdminField
                      label="Çark Altı Ek Görseli Üstten Boşluk"
                      value={campaign.wheel.screenCenterBgFromTop}
                      type="number"
                      onChange={(value) => handleWheelChange("screenCenterBgFromTop", value !== "" ? parseInt(value) : null)}
                      helperText="Çarkın altında yer alacak ek görselin üstten boşluk değerini rakam olarak giriniz"
                    />
                    <AdminField
                      label="Çark Kaplama ve Dekoru Döndürme"
                      value={campaign.wheel.spinnerRotate}
                      type="number"
                      onChange={(value) => handleWheelChange("spinnerRotate", value !== "" ? parseFloat(value.toFixed(1)) : null)}
                      helperText="Çark dekorunu ve kaplamasını dairesel olarak döndürmek için derece ayarını değiştirebilirsiniz. Not: Aşağıdaki şeffaflaştırmayı açmak dekor için dönme ayarını uygularken yardımcı olabilir."
                    />
                    <AdminField
                      label="Çark Dekoru Şeffaflaştırma"
                      type="switch"
                      value={campaign.wheel.spinnerCoverOpacity}
                      onChange={(value) =>
                        handleWheelChange("spinnerCoverOpacity", value)
                      }
                      helperText="Çark dekorunun altındakileri görebilmek için geçici olarak şeffaflaştırmayı açabilirsiniz"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={6} className="center-inside">
                  {id && (
                    <>
                      <Typography variant="h6" gutterBottom>
                        Oyun Ekranı Önizleme
                      </Typography>
                      <AdminField
                        type="radio"
                        value={iframeSize.width === 360 ? '9:16' : '16:9'}
                        onChange={handleSizeChange}
                        selectOptions={[
                          { label: "9:16 (1080x1920)", value: '9:16' },
                          { label: "16:9 (1920x1080)", value: '16:9' }
                        ]}
                      />
                      <Button
                        variant="contained"
                        color="warning"
                        name="showGameScreen"
                        onClick={handleShowGameScreen}
                        startIcon={isLoaded ? <RefreshIcon /> : <VideogameAssetIcon />}
                        sx={{ my: 3 }}
                      >
                        {isLoaded ? 'Kaydet ve Oyun Ekranını Yenile' : 'Kaydet ve Oyun Ekranını Göster'}
                      </Button>
                      <br />
                      {iframeSrc ? (
                        <div id={iframeSize.width === 360 ? "game-preview-portrait" : "game-preview-landscape"}>
                            <iframe
                              width={iframeSize.width}
                              height={iframeSize.height}
                              src={iframeSrc}
                              title="Game Screen"
                              style={{ border: '2px solid #e0e0e0' }}
                            />
                        </div>
                      ) : (
                        <div style={{ width: iframeSize.width, height: iframeSize.height, backgroundColor: '#e0e0e0', textAlign: 'center', lineHeight: `${iframeSize.height}px` }}>
                          <span>Oyun ekranını buradan inceleyebilirsiniz</span>
                        </div>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
              <Divider sx={{ mb: 5 }} />
              <Typography variant="h5" gutterBottom sx={{ mb: 5 }}>
                Çark Renk Ayarları
              </Typography>
              <Grid container spacing={4} sx={{ mb: 4 }}>
                <AdminField
                  label="Çark Rengi 1"
                  type="color"
                  inline
                  inlineFix={1}
                  value={campaign.wheel.colorFirst}
                  onChange={(value) => handleWheelChange("colorFirst", value)}
                  helperText="Çarkın ilk rengini giriniz"
                />
                <AdminField
                  label="Çark Rengi 2"
                  type="color"
                  inline
                  inlineFix={5}
                  value={campaign.wheel.colorSecond}
                  onChange={(value) => handleWheelChange("colorSecond", value)}
                  helperText="Çarkın ikinci rengini giriniz"
                />
                <AdminField
                  label="Çark Yazı Rengi 1"
                  type="color"
                  inline
                  inlineFix={1}
                  value={campaign.wheel.wheelTextColor || "#000"}
                  onChange={(value) =>
                    handleWheelChange("wheelTextColor", value)
                  }
                  helperText="Çarkı ilk yazı rengini giriniz"
                />
                <AdminField
                  label="Çark Yazı Rengi 2"
                  type="color"
                  inline
                  inlineFix={5}
                  value={campaign.wheel.wheelTextColor2 || "#fff"}
                  onChange={(value) =>
                    handleWheelChange("wheelTextColor2", value)
                  }
                  helperText="Çarkı ikinci yazı rengini giriniz"
                />
                <AdminField
                  label="Çark Kenar Rengi"
                  type="color"
                  inline
                  inlineFix={1}
                  value={campaign.wheel.wheelBorderColor || "#e60f0f"}
                  onChange={(value) =>
                    handleWheelChange("wheelBorderColor", value)
                  }
                  helperText="Çark çevresindeki kenar rengini giriniz"
                />
                <AdminField
                  label="Çark Pin Rengi"
                  type="color"
                  inline
                  inlineFix={5}
                  value={campaign.wheel.wheelPinColor || "#eeeeee"}
                  onChange={(value) =>
                    handleWheelChange("wheelPinColor", value)
                  }
                  helperText="Çark pininin rengini giriniz"
                />
                <AdminField
                  label="Çark Dilim Arası Çizgisi"
                  type="switch"
                  inline
                  inlineFix={1}
                  value={campaign.wheel.radiusLine}
                  onChange={(value) => handleWheelChange("radiusLine", value)}
                  helperText="Çarkın dilimleri arasında çizgi kullanacaksanız buradan aktive ediniz"
                />
                <AdminField
                  label="Çark Dilim Arası Çizgi Rengi"
                  type="color"
                  inline
                  inlineFix={5}
                  value={campaign.wheel.radiusLineColor || "#e60f0f"}
                  onChange={(value) =>
                    handleWheelChange("radiusLineColor", value)
                  }
                  helperText="Çark dilimleri arasında çizgi kullanıyorsanız buradan rengini giriniz"
                />
              </Grid>
            </div>
            <div
              role="tabpanel"
              hidden={tabValue !== 3}
              id="simple-tabpanel-3"
              aria-labelledby="simple-tab-3"
            >
              <Grid container spacing={4} sx={{ mb: 4 }}>
                <Grid item xs={2}></Grid>
                <Grid item xs={10}>
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<AnalyticsIcon />}
                    color="error"
                    onClick={handleResetStocks}
                    sx={{ mr: 2 }}
                  >
                    Stokları Başlangıç Değerlerine Al
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<VideogameAssetIcon />}
                    color="warning"
                    onClick={handleCalculateProbabilities}
                  >
                    Olasılıkları Stoklardan Hesapla
                  </Button>
                  
                </Grid>
                <AdminField
                  label="Dilim Sayısı"
                  type="select"
                  value={campaign.wheel.sliceAmount}
                  onChange={(value) =>
                    handleSliceAmountChange({
                      target: { value: value.toString() },
                    } as React.ChangeEvent<HTMLInputElement>)
                  }
                  disabled={id != null}
                  helperText={
                    id != null
                      ? "Dilim sayısı kampanya oluşturulduktan sonra değiştirilemez."
                      : "Çarkta kaç dilim olacağını seçiniz"
                  }
                  selectOptions={[
                    { label: "6", value: 6 },
                    { label: "8", value: 8 },
                    { label: "10", value: 10 },
                  ]}
                />

                <Grid item xs={2}>
                  <Typography variant="subtitle2">Dilimler</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Card sx={{ mb: 2 }}>
                    <CardContent>
                      <Alert sx={{ my: 3 }} severity="info">
                        Dilimlerin <strong>Olasılık</strong> değerlerini{" "}
                        <strong>0</strong> ile <strong>100</strong> arasında ve{" "}
                        ondalık değeri varsa <strong>virgül</strong> ile tek basamak
                        olarak giriniz. Değerlerin toplamı <strong>100</strong>'e eşit
                        olmalıdır. <strong>Örnek:</strong> 6 adet dilim için
                        olasılıklar; 10, 20, 10, 30 , 15, 15.
                      </Alert>
                      <Alert sx={{ my: 3 }} severity="error">
                        <strong>Önemli!:</strong> Eğer{" "}
                        <strong>stok kontrolü</strong> olan bir oyun
                        kurguluyorsanız yani herhangi bir dilimin stok değerini
                        girdiyseniz{" "}
                        <strong>
                          hiçbir Olasılık alanını boş bırakmayınız
                        </strong>
                        . Aksi halde stok düşüşüyle birlikte yeniden dağıtılan
                        olasılık hesapları boş bırakılan her bir alan için
                        sıfıra düşecektir. Stok sınırı yer alan oyunlarda her
                        bir dilime olasılık değeri atamanız önemlidir.
                      </Alert>
                      <Alert sx={{ my: 3 }} severity="error">
                        <strong>Başlangıç Stoğu</strong> değeri oyunun başındaki
                        stok oranı olarak belirlenir ve <strong>Stok Düşme Kontrolü</strong>{" "}
                        ve <strong>Stokları Başlangıç Değerlerine Al</strong>ma özelliği için önemlidir.
                        Bu yüzden bu alanı istisna bir durum olmadıkça oyun içerisinde
                        değiştirmeyiniz.
                      </Alert>
                      {Array.isArray(campaign.wheel.slices) &&
                        campaign.wheel.slices.map((slice: any, index: any) => (
                          <div key={slice.id || index}>
                            <h4>Dilim {index + 1}</h4>
                            <Grid container spacing={3}>
                              <AdminField
                                type="disabled"
                                value={slice.listOrder}
                                fullWidth={false}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(index, "listOrder", value)
                                }
                              />
                              <AdminField
                                label="Ödül Adı"
                                value={slice.prizeValue}
                                fullWidth={false}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(index, "prizeValue", value)
                                }
                                error={!!errors.slices}
                                errorMessage={errors.slices}
                              />
                              <AdminField
                                label="Olasılık"
                                type="decimal"
                                inputProps={{ step: "0.1", min: 0, max: 100, className: 'probability-field' }}
                                className= "add-percentage"
                                value={
                                  slice.probability !== null
                                    ? parseFloat((slice.probability * 100).toFixed(1))
                                    : ""
                                }
                                fullWidth={false}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(
                                    index,
                                    "probability",
                                    value !== "" ? parseFloat(value) / 100 : null
                                  )
                                }
                              />                              
                              <AdminField
                                label="Stok"
                                type="number"
                                value={slice.stock !== null ? slice.stock : ""}
                                fullWidth={false}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(
                                    index,
                                    "stock",
                                    value !== "" ? parseInt(value) : null
                                  )
                                }
                              />
                              <Grid item xs={2}></Grid>

                              <Grid item>
                                <IconButton
                                  aria-label="move slice up"
                                  onClick={() => moveSlice(index, "up")}
                                  disabled={index === 0}
                                >
                                  <ArrowUpwardIcon />
                                </IconButton>
                                <IconButton
                                  aria-label="move slice down"
                                  onClick={() => moveSlice(index, "down")}
                                  disabled={
                                    index === campaign.wheel.slices.length - 1
                                  }
                                >
                                  <ArrowDownwardIcon />
                                </IconButton>
                              </Grid>
                              <AdminField
                                label="Dilim Görseli"
                                value={slice.wedgeImage}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(index, "wedgeImage", value)
                                }
                                helperText="Dilim için görsel ekleyiniz"
                                type="button"
                                buttonText="Galeri'den Seç"
                                onClick={() =>
                                  openImagePicker(`slice-${index}-wedgeImage`)
                                }
                              />
                              <AdminField
                                label="Ödül Görseli"
                                value={slice.prizeImage}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(index, "prizeImage", value)
                                }
                                helperText="Ödül için görsel ekleyiniz"
                                type="button"
                                buttonText="Galeri'den Seç"
                                onClick={() =>
                                  openImagePicker(`slice-${index}-prizeImage`)
                                }
                              />
                              <AdminField
                                label="Ödül mü?"
                                type="switch"
                                value={slice.isPrize}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(index, "isPrize", value)
                                }
                                helperText="Yalnızca pas veya İflas dilimi ise kapatınız"
                              />
                              <Grid item xs={2}></Grid>
                              
                              <Grid item xs={1}></Grid>
                              <AdminField
                                label="Başlangıç Stoğu"
                                type="number"
                                value={slice.initialStock !== null ? slice.initialStock : ""}
                                fullWidth={false}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(
                                    index,
                                    "initialStock",
                                    value !== "" ? parseInt(value) : null
                                  )
                                }
                                helperText="Stoğun oyun başındaki değeridir. Oyun içinde özel durum olmadıkça değiştirmeyiniz."
                              />
                              <AdminField
                                label="Stok Düşme Kontrolü"
                                type="switch"
                                value={slice.onStockControl}
                                inline
                                onChange={(value) =>
                                  handleSliceChange(index, "onStockControl", value)
                                }
                                helperText="Stok oranına bağlı olasılık düşüşünden etkilenecekse işaretleyiniz"
                              />
                              {slice.onStockControl && slice.stockDropped ? (
                                <>
                                  <div className="notification true">
                                    <span>Olasılık düşürüldü!</span>
                                    <small>Stoklar başlangıç değerine alınmadan dilim bir daha olasılık düşürmeden etkilenmeyecektir.</small>
                                  </div>
                                </>
                              ) : slice.onStockControl && (
                                <div className="notification"><span>Olasılık henüz düşürülmedi</span></div>
                              )
                              }                              
                            </Grid>
                            <Divider sx={{ my: 3 }} />
                          </div>
                        ))}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </div>
            <div
              role="tabpanel"
              hidden={tabValue !== 4}
              id="simple-tabpanel-4"
              aria-labelledby="simple-tab-4"
            >
              <Grid container spacing={4} sx={{ mb: 4 }}>
                <Alert sx={{ my: 3, mx: 12 }} severity="info">
                  Bu bölüm sadece <strong>Stok düşme kontrolü</strong> değeri
                  açık dilimlerde işe yarayacaktır. Dilimin <strong>Başlangıç Stoğu</strong>{" "}
                  değeri oyunun başındaki stok oranı olarak belirlenir ve stok
                  düştükçe bu sekmedeki <strong>Stok Düşme Oranı</strong>na
                  ulaştığında <strong>Olasılık Azalma Oranı</strong> kadar
                  olasılık düşecektir. <br /><br />
                  <strong>Örnek:</strong> Dilim başlangıç stoğunun 100 olduğu
                  alan için Stok 20'ye düştüğünde olaslığının yarıya düşmesini
                  istiyorsanız Stok DÜşme Oranı 20, Olaslık Azalma Oranı 50
                  olmalıdır.
                </Alert>
                <AdminField
                  label="Stok Düşme Oranı"
                  type="number"
                  value={campaign.wheel.stockControlRate}
                  fullWidth={false}
                  onChange={(value) => handleWheelChange("stockControlRate", value)}
                  helperText="Dilimin başlangıç stoğunun yüzde kaç oranına düştüğünde dilim olasılığının azalacağını buradan ayarlayabilirsiniz"
                />
                <AdminField
                  label="Olasılık Azalma Oranı"
                  type="number"
                  value={campaign.wheel.probabilityDropRate}
                  fullWidth={false}
                  onChange={(value) => handleWheelChange("probabilityDropRate", value)}
                  helperText="Stok yüzdesi düştüğünde olasılığının yüzde kaç oranında düşeceğini buradan ayarlayabilirsiniz. Dilimin düşen olasılık yüzdesi diğer dilimlerin olasılıklarına pay edilecektir."
                />
              </Grid>
            </div>
            <div
              role="tabpanel"
              hidden={tabValue !== 5}
              id="simple-tabpanel-5"
              aria-labelledby="simple-tab-5"
            >
              <Grid container spacing={4} sx={{ mb: 4 }}>
                <Alert sx={{ my: 3, mx: 12 }} severity="info">
                  Aşağıdaki alanlardan kullanıcı bilgileri formunu özelleştirebilirsiniz.
                  Hangi alanların dahil, hangilerinin kapalı olacağını açıp kapatabilir,
                  yasal sözleşme onay kutucuğu yazısını <strong>&#40;metnin kendisini değil,
                  onu bu kutucuğa a etiketi olarak ekleyeceksiniz. Örnek metinden
                  faydalanabilirsiniz&#41;</strong>,<strong>Onay İzin Kutucuğu Yazısı</strong>nı
                  ekleyebilirsiniz. SÖzleşme ve onay alanları boş kaldığında
                  görünmeyecektir.<br /><br />
                  Ek alanın ise sadece etiket ismini yazdığınız anda istediğiniz ek bilgi için
                  bu alan da doldurulabilir olacaktır.<br /><br />
                  <strong>Link içeren örnek onay metni:</strong><br />
                  <code>&lt;<strong>a href="</strong>https://www.tokenflex.com.tr/yasal-metin-bilgilendirme/tokenflex-kurumsal-musteri-temsilcisi-calisani-aydinlatma-metni<strong>" target="_blank"&gt;</strong>TokenFlex Kurumsal Müşteri Temsilcisi/Çalışanı Aydınlatma Metni<strong>&lt;/a&gt;</strong>’ni okudum, anladım.</code>
                </Alert>
                <AdminField
                  label="E-posta"
                  type="switch"
                  value={campaign.formState.formEmail}
                  onChange={(value) =>
                    handleFormStateChange("formEmail", value)
                  }
                />
                <AdminField
                  label="Telefon"
                  type="switch"
                  value={campaign.formState.formPhone}
                  onChange={(value) =>
                    handleFormStateChange("formPhone", value)
                  }
                />
                <AdminField
                  label="Firma"
                  type="switch"
                  value={campaign.formState.formCompany}
                  onChange={(value) =>
                    handleFormStateChange("formCompany", value)
                  }
                />
                <AdminField
                  label="Cinsiyet"
                  type="switch"
                  value={campaign.formState.formGender}
                  onChange={(value) =>
                    handleFormStateChange("formGender", value)
                  }
                />
                <AdminField
                  label="Yasal Sözleşme Onay Kutucuğu Yazısı"
                  value={campaign.formState.formContract}
                  helperText="Eğer yasal kontrat onayı kullanacaksanız doldurunuz. Kontrat sayfasının linkini yukarıdaki örnekteki gibi uygulamaya dikkat ediniz."
                  onChange={(value) => handleFormStateChange("formContract", value)}
                  multiline
                  rows={2}
                />
                <AdminField
                  label="Onay İzin Kutucuğu Yazısı"
                  value={campaign.formState.formAddPermission}
                  helperText="Eğer yasal kontrata ek olarak ikinci bir onay izin kutucuğu kullanacaksanız doldurunuz."
                  onChange={(value) => handleFormStateChange("formAddPermission", value)}
                  multiline
                  rows={2}
                />
                <AdminField
                  label="Ek Alan Etiket İsmi"
                  value={campaign.formState.bonusField}
                  onChange={(value) => handleFormStateChange("bonusField", value)}
                  helperText="Formda yer alacak ekstra özel bir ek alan kullanacaksanız bu alanın sadece etiketinde yer alacak başlığı yazınız."
                />
              </Grid>
            </div>
          </>
        )}
        <Divider />
        <Button
          variant="contained"
          color="primary"
          type="button"
          onClick={handleRegularSubmit}
          sx={{ my: 4 }}
        >
          Kaydet
        </Button>
      </form>
      <ImagePickerModal
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSelect={handleImageSelect}
      />
    </AdminLayout>
  );
}
