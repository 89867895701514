import { useEffect, useState } from "react";
import AdminLayout from "../layout/AdminLayout";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { MenuItem, TextField, Chip, Grid, Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import agent from "../api/agent";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { GameDto } from "../models/gameDto";
import { FormState } from "../models/formState";
import * as XLSX from "xlsx";

interface CampaignData {
  id: string;
  name: string;
  formState: FormState;
}

export default function ListPlayers() {
  const [games, setGames] = useState<GameDto[]>([]);
  const [campaigns, setCampaigns] = useState<CampaignData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCampaignId, setSelectedCampaignId] = useState<string>("");

  const fetchGames = async () => {
    setLoading(true);
    try {
      const response = await agent.Games.list();
      setGames(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Oyuncular getirilemedi.");
    }
  };

  const fetchCampaigns = async () => {
    try {
      const response = await agent.Campaigns.listLight();
      setCampaigns(
        response.map((campaign) => ({ id: campaign.id, name: campaign.title, formState: campaign.formState }))
      );
    } catch (error) {
      console.log(error);
      toast.error("Kampanyalar getirilemedi.");
    }
  };

  useEffect(() => {
    fetchGames();
    fetchCampaigns();
  }, []);

  const handleCampaignChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCampaignId(event.target.value);
  };

  const filteredGames = selectedCampaignId
    ? games.filter((game) => game.campaignId === selectedCampaignId)
    : games;

  const columns: GridColDef[] = [
    {
      field: "datePlayed",
      headerName: "Oynanma Tarihi",
      width: 160,
      renderCell: (params: any) =>
        dayjs(params.row.datePlayed).add(3, 'hour').format("DD.MM.YYYY HH:mm"),
    },
    {
      field: "prize",
      headerName: "Ödül",
      width: 200,
      renderCell: (params: any) =>
        params.row.isPrize ? (
          params.row.prize
        ) : (
          <Chip label="Kazanamadı" color="error" />
        ),
    },
    {
      field: "name",
      headerName: "Ad Soyad",
      width: 180,
    },
    {
      field: "gender",
      headerName: "Cinsiyet",
      width: 100,
      renderCell: (params: any) =>
        params.row.name !== " " && params.value !== "Male" ? (
          <Chip
            label={
              params.value === "1"
                ? "Erkek"
                : params.value === "2"
                ? "Kadın"
                : "Erkek"
            }
          />
        ) : "",
    },
    {
      field: "email",
      headerName: "E-posta",
      width: 200,
    },
    {
      field: "phone",
      headerName: "Telefon",
      width: 140,
    },
    {
      field: "company",
      headerName: "Firma",
      width: 160,
    },
    {
      field: "bonusField",
      headerName: "Ek Alan",
      width: 160,
    },
    {
      field: "campaignName",
      headerName: "Kampanya Adı",
      width: 180,
    },
  ];

  const handleOnExport = () => {
    const selectedCampaign = campaigns.find(
      (campaign) => campaign.id === selectedCampaignId
    );
    let bonusFieldLabel : string | undefined = "Ek Alan";
    const filteredData = filteredGames.map((game) => {
      const playerData: any = {
        datePlayed: dayjs(game.datePlayed).add(3, 'hour').format("DD.MM.YYYY HH:mm"),
        prize: game.isPrize ? game.prize : "Kazanamadı",
        name: game.name,
      };
      if (selectedCampaignId) {
        if (selectedCampaign?.formState.formEmail) playerData.email = game.email;
        if (selectedCampaign?.formState.formPhone) playerData.phone = game.phone;
        if (selectedCampaign?.formState.formCompany) playerData.company = game.company;
        if (selectedCampaign?.formState.formGender) playerData.gender = game.name !== " " ? game.gender === "2" ? "Kadın" : "Erkek" : "";
        if (selectedCampaign?.formState.bonusField) bonusFieldLabel = game.bonusField.split(":")[0];
        if (selectedCampaign?.formState.bonusField) playerData.bonusField = game.bonusField.split(":").pop();
      } else {
        playerData.email = game.email;
        playerData.phone = game.phone;
        playerData.company = game.company;
        playerData.gender = game.name != " " && game.gender !== "Male" ? game.gender === "2" ? "Kadın" : "Erkek" : "";
        playerData.bonusField = game.bonusField;
        bonusFieldLabel = "Ek Alan";
      }
      return playerData;
    });

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(filteredData);
    const campaignName = selectedCampaign ? selectedCampaign.name.toLowerCase().replace(/\s+/g, '-') : "tüm-kampanyalar";
    const currentDate = dayjs().format("DD-MM-YYYY");

    const headers = ["Oynanma Tarihi", "Ödül", "Ad Soyad"];
    if (selectedCampaignId) {
      if (selectedCampaign?.formState.formEmail) headers.push("E-Posta");
      if (selectedCampaign?.formState.formPhone) headers.push("Telefon");
      if (selectedCampaign?.formState.formCompany) headers.push("Firma");
      if (selectedCampaign?.formState.formGender) headers.push("Cinsiyet");
      if (selectedCampaign?.formState.bonusField) headers.push(bonusFieldLabel);
    } else {
      headers.push("E-Posta");
      headers.push("Telefon");
      headers.push("Firma");
      headers.push("Cinsiyet");
      headers.push(bonusFieldLabel);
    }

    headers.forEach((header, index) => {
      const cellRef = XLSX.utils.encode_cell({ r: 0, c: index });
      worksheet[cellRef] = { t: "s", v: header, s: { font: { bold: true } } };
    });

    const headerStyle = { font: { bold: true } };
    const rangeRef = worksheet["!ref"];
    if (rangeRef) {
      // Ensure that !ref is not undefined
      const range = XLSX.utils.decode_range(rangeRef);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const address = XLSX.utils.encode_cell({ c: C, r: 0 });
        if (!worksheet[address]) continue;
        worksheet[address].s = headerStyle;
      }
    }

    const columnWidths = headers.map(() => ({ wch: 20 }));;
    worksheet["!cols"] = columnWidths;

    XLSX.utils.book_append_sheet(workbook, worksheet, "Carkifelek");

    XLSX.writeFile(
      workbook,
      `${campaignName}-${currentDate}-oyuncu-listesi.xlsx`
    );
  };

  return (
    <>
      <AdminLayout title="Oyuncular">
        <Grid
          container
          spacing={2}
          marginBottom={2}
          sx={{ justifyContent: "space-between" }}
        >
          <Grid item xs={6} sm={6} md={4}>
            <TextField
              select
              label="Kampanya Seç"
              value={selectedCampaignId}
              onChange={handleCampaignChange}
              fullWidth
              variant="outlined"
            >
              <MenuItem value="">
                <em>Tümü</em>
              </MenuItem>
              {campaigns.map((campaign) => (
                <MenuItem key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} sm={6} md={4}>
            <Button
              variant="contained"
              startIcon={<DownloadIcon />}
              color="success"
              onClick={handleOnExport}
            >
              Excel Çıktı Al
            </Button>
          </Grid>
        </Grid>
        <DataGrid
          rows={filteredGames}
          columns={columns}
          loading={loading}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[10, 25]}
          checkboxSelection
        />
      </AdminLayout>
    </>
  );
}
