import SpinWheel from "../../components/SpinWheel/SpinWheel";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Campaign } from "../models/campaign";
import agent from "../api/agent";
import CenteredCard from "../layout/CenteredCard";
import { env } from "../../app/utils/env";

export default function GameBoard() {
  const { id } = useParams<{ id: string }>();
  const [campaign, setCampaign] = useState<Campaign | null>(null);

  let background = "";
  let backgroundSize = "";
  const screenColor = campaign?.wheel.screenColor || "#E60805";
  if (campaign?.wheel.screenBg) {
    if (campaign?.wheel.screenCenterBg) {
      background = `url(${env + campaign.wheel.screenCenterBg}) center ${campaign.wheel.screenCenterBgFromTop || '1300'}px no-repeat, url(${env + campaign.wheel.screenBg}) center center no-repeat, ${screenColor}`;
      backgroundSize = "auto, cover, auto";
    } else {
      background = `url(${env + campaign.wheel.screenBg}) center center no-repeat, ${screenColor}`
      backgroundSize = "cover, auto";
    }
  } else {
    backgroundSize = "auto";
    if (campaign?.wheel.screenCenterBg) {
      background = `url(${env + campaign.wheel.screenCenterBg}) center ${campaign.wheel.screenCenterBgFromTop || '1300'}px no-repeat, ${screenColor}`;
    } else {
      background = screenColor;
    }
  }
  let wheelDiameter = campaign?.wheel?.wheelDiameter || 900;
  let centerImgSize = (wheelDiameter / 5.14).toFixed(1);
  let pointerPinWidth = (wheelDiameter / 9).toFixed(1);
  let pointerPinHeight = (wheelDiameter / 6.92).toFixed(1);
  let pointerPinTop = (wheelDiameter * 0.12).toFixed(1);

  let wheelStyle = `
    .wheel-container {
      width: ${wheelDiameter}px;
      height: ${wheelDiameter}px;
    }
    .center-img {
      width: ${centerImgSize}px;
      height: ${centerImgSize}px;
    }
    .pointer-pin {
      width: ${pointerPinWidth}px !important;
      height: ${pointerPinHeight}px !important;
      left: 47% !important;
      top: -${pointerPinTop}px !important;
    }
  `;

  const fetchCampaign = async () => {
    if (id) {
      try {
        const fetchedCampaign = await agent.Campaigns.details(id);
        fetchedCampaign.wheel.slices.sort((a, b) => a.listOrder - b.listOrder);
        fetchedCampaign.wheel.slices.forEach(slice => {
          slice.probability = slice.probability && parseFloat(slice.probability.toFixed(3));
        });
        setCampaign(fetchedCampaign);
      } catch (error) {
        console.error("Failed to fetch campaign:", error);
      }
    } else {
      console.error("No campaign id provided");
    }
  };

  useEffect(() => {
    fetchCampaign();
  }, [id]);

  const handleStockDepletion = () => {
    fetchCampaign(); // Refetch the campaign data to update probabilities
  };

  if (!campaign) {
    return <CenteredCard>Yükleniyor...</CenteredCard>;
  }

  return (
    <>
      <style>
        {campaign.wheel.titleFromTop && (`
          .game-screen h3 {
            margin-top: ${campaign.wheel.titleFromTop}px;
            max-width: ${campaign.wheel.titleWidth}%;
          }
        `)}
        {campaign.wheel.titleFontSize && (`
          .game-screen h3 {
            font-size: ${campaign.wheel.titleFontSize}px;
          }
        `)}
        {campaign.wheel.logoHeight && (`
          .game-screen .screen-logo {
            max-height: ${campaign.wheel.logoHeight}px;
          }
        `)}
        {campaign.wheel.logoFromTop && (`
          .game-screen .screen-logo {
            margin-top: ${campaign.wheel.logoFromTop}px;
          }
        `)}
        {campaign.wheel.wheelFromTop && (`
          .wheel-container {
            top: ${campaign.wheel.wheelFromTop}%;
          }
        `)}
        {wheelStyle}
        {campaign.customCSS}
      </style>
      <main
        className="game-screen"
        style={{
          color: campaign.wheel.textColor || "fff",
          background: background,
          backgroundSize: backgroundSize,
        }}
      >
        {campaign.wheel.logo && (
          <img
            className="screen-logo"
            src={env + campaign.wheel.logo}
            alt={campaign.title}
          ></img>
        )}
        <h3>{campaign.wheel.pageTitle}</h3>
        <SpinWheel
          collectUserData={campaign.collectUserData}
          wheel={campaign.wheel}
          slices={campaign.wheel.slices}
          formState={campaign.formState}
          campaignId={campaign.id}
          onStockDepletion={handleStockDepletion}
          modalText={campaign.modalText}
          modalBg={campaign.modalBg}
        />
      </main>
    </>
  );
}
