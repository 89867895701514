import styled from 'styled-components';
import { NonDraggableImage } from '../common/styledComponents';
import { env } from "../../../env"

interface RotationContainerProps {
  classKey: string;
  startSpinningTime: number;
  continueSpinningTime: number;
  stopSpinningTime: number;
  wholeSpinningTime: number;
  startRotationDegrees: number;
  finalRotationDegrees: number;
  disableInitialAnimation: boolean;
  backwardsAnimation: boolean;
  noAnimationSteps: boolean;
  coverImage?: string;
  spinnerRotate: number;
}

export const RouletteContainer = styled.div`
  position: relative;
  width: 80vw;
  max-width: 445px;
  height: 80vw;
  max-height: 445px;
  object-fit: contain;
  flex-shrink: 0;
  z-index: 5;
  pointer-events: none;
`;

export const RotationContainer = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    ![
      'classKey',
      'startSpinningTime',
      'continueSpinningTime',
      'stopSpinningTime',
      'wholeSpinningTime',
      'startRotationDegrees',
      'finalRotationDegrees',
      'disableInitialAnimation',
      'backwardsAnimation',
      'noAnimationSteps',
      'coverImage',
      'spinnerRotate'
    ].includes(prop),
})<RotationContainerProps>`
  position: absolute;
  width: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  
  transform: rotate(${props => props.startRotationDegrees}deg);

  ${props =>
    props.coverImage && `
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url(${env + props.coverImage});
      background-size: 100% 100%;
      z-index: -1;
      transform: rotate(${120 + props.spinnerRotate}deg);
    }
  `}

  &.started-spinning {
    ${props =>  
      props.noAnimationSteps || props.backwardsAnimation ? `
      animation: fullSpin-${props.classKey} ${(props.startSpinningTime + props.continueSpinningTime + props.stopSpinningTime) / 1000}s ease-out 0s 1 normal ${props.backwardsAnimation ? 'backwards' : 'forwards'} running;
      ` : `
      animation: spin-${props.classKey} ${props.startSpinningTime / 1000}s cubic-bezier(
          0.71,
          ${props.disableInitialAnimation ? 0 : -0.29},
          0.96,
          0.9
        ) 0s 1 normal forwards running,
      continueSpin-${props.classKey} ${props.continueSpinningTime / 1000}s linear ${props.startSpinningTime / 1000}s 1 normal forwards running,
      stopSpin-${props.classKey} ${props.stopSpinningTime / 1000}s cubic-bezier(0, 0, 0.35, 1.02) ${(props.startSpinningTime + props.continueSpinningTime) / 1000}s 1 normal forwards
        running;
      `}

  }

  @keyframes spin-${({ classKey }) => classKey} {
    from {
      transform: rotate(${props => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${props => props.startRotationDegrees + 360}deg);
    }
  }
  @keyframes continueSpin-${({ classKey }) => classKey} {
    from {
      transform: rotate(${props => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${props => props.startRotationDegrees + 360}deg);
    }
  }
  @keyframes stopSpin-${({ classKey }) => classKey} {
    from {
      transform: rotate(${props => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${props => 1440 + props.finalRotationDegrees}deg);
    }
  }

  @keyframes fullSpin-${({ classKey }) => classKey} {
    from {
      transform: rotate(${props => props.startRotationDegrees}deg);
    }
    to {
      transform: rotate(${props => props.finalRotationDegrees + (props.backwardsAnimation ? -360 : 360) * props.wholeSpinningTime}deg);
    }
  }
`;

export const RoulettePointerImage = styled(NonDraggableImage)`
  position: absolute;
  z-index: 5;
  width: 17%;
  right: 6px;
  top: 15px;
`;
