import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import AdminLayout from "../layout/AdminLayout";
import { Alert, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";
import { useEffect } from "react";
import GuideCard from "../layout/GuideCard";

export default function PhotoGuide() {
  const navigate = useNavigate();

  useEffect(() => {
    let lightbox: any = new PhotoSwipeLightbox({
      gallery: "#photo-gallery",
      children: "a",
      pswpModule: () => import("photoswipe"),
    });
    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  return (
    <AdminLayout title="Görsel Ekleme Kılavuzu">
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={() => navigate("/photo-gallery")}
              sx={{ mb: 2, mr: 3 }}
            >
              Galeriye Dön
            </Button>
          </Grid>
        </Grid>
        <Grid container spacing={4} id="photo-gallery">
          <Grid item xs={12}>
            <Alert sx={{ my: 2 }} severity="info">
              Kampanyalarda Çarkıfelek ve Dilim alanlarında kullanacağınız
              görsellerin ölçüler ve format bilgileri için aşağıdaki kılavuzdan
              faydalanabilirsiniz.
            </Alert>
          </Grid>
          <GuideCard
            title="Ekran Logosu"
            location="Çarkın hemen üstü"
            dimensions="Maksimum 500px yüksekliği ile orantılı genişlik"
            images={[
              { name: "guide-ekran-logosu-1.jpg", width: 949, height: 518 },
              { name: "guide-ekran-logosu-2.jpg", width: 529, height: 624 },
            ]}
          />
          <GuideCard
            title="Arkaplan Görseli"
            location="Oyun ekranı tam arkaplan görseli"
            dimensions="Oyunun oynanacağı dijital ekrana tam oturacak görsel"
            images={[
              { name: "guide-arkaplan-gorseli-1.jpg", width: 952, height: 522 },
              { name: "guide-arkaplan-gorseli-2.jpg", width: 378, height: 815 },
            ]}
          />
          <GuideCard
            title="Çark Altı Ek Görseli"
            location="Oyun ekranı çark altında ortada yer alan görsel"
            dimensions="Maksimum 500px yüksekliği ile orantılı genişlik"
            images={[
              { name: "guide-cark-alti-gorseli-1.jpg", width: 846, height: 619 },
              { name: "guide-cark-alti-gorseli-2.jpg", width: 600, height: 828 },
            ]}
          />
          <GuideCard
            title="Çark Pin Görseli"
            location="Çarkın çevrildiği iğne (çark pini) için özel görsel"
            dimensions="İstediğiniz ölçülerde ekleyeceğiniz pin 42 piksek yüksekliğinde görüntülenecektir"
            format=".png (Aşağıdaki ilk görsel örnektir. Büyütüp indirebilirsiniz)"
            images={[
              { name: "guide-cark-pin-gorseli-1.png", width: 228, height: 311 },
              { name: "guide-cark-pin-gorseli-2.png", width: 937, height: 527 },
              { name: "guide-cark-pin-gorseli-3.png", width: 466, height: 664 },
            ]}
          />
          <GuideCard
            title="Çark Ortası Görseli"
            location="Çarkın ortasındaki yuvarlak görsel"
            dimensions="86x86 piksel ölçülerinde arkası şeffaf yuvarlak"
            format=".png (Aşağıdaki ilk görsel örnektir. Büyütüp indirebilirsiniz)"
            images={[
              { name: "guide-cark-ortasi-gorseli-1.png", width: 86, height: 86 },
              { name: "guide-cark-ortasi-gorseli-2.jpg", width: 941, height: 502 },
              { name: "guide-cark-ortasi-gorseli-3.jpg", width: 552, height: 778 },
            ]}
          />
          <GuideCard
            title="Çark Dekoru Görseli"
            location="Çarkın etrafındaki dekoratif görsel"
            dimensions="350x350 piksel ölçülerinde arkası şeffaf, ortası boş yuvarlak"
            format=".png (Aşağıdaki ilk görsel örnektir. Büyütüp indirebilirsiniz)"
            images={[
              { name: "guide-cark-dekoru-gorseli-1.png", width: 350, height: 350 },
              { name: "guide-cark-dekoru-gorseli-2.jpg", width: 955, height: 590 },
            ]}
          />
          <GuideCard
            title="Dilim Görseli"
            location="Çark dilimi üzerinde ödül ismi yerine eklenen görsel"
            dimensions="122x140 piksel, ortada tam örnek görsel ebatlarında"
            format=".png, .webp (Aşağıdaki ilk iki görsel örnektir. Büyütüp indirebilirsiniz)"
            images={[
              { name: "guide-dilim-gorseli-1.webp", width: 122, height: 140 },
              { name: "guide-dilim-gorseli-2.webp", width: 122, height: 140 },
              { name: "guide-dilim-gorseli-3.jpg", width: 1565, height: 669 },
              { name: "guide-dilim-gorseli-4.jpg", width: 447, height: 737 },
            ]}
          />
          <GuideCard
            title="Ödül Görseli"
            location="Ödül kazanıldığında pop-up üzerinde gözüken görsel"
            dimensions="500 pikselden büyük olmayan ortanlı ölçülerde"
            format=".png, .webp (Aşağıdaki ilk görsel örnektir. Büyütüp indirebilirsiniz)"
            images={[
              { name: "guide-odul-gorseli-1.png", width: 500, height: 280 },
              { name: "guide-odul-gorseli-2.jpg", width: 1589, height: 683 },
              { name: "guide-odul-gorseli-3.jpg", width: 735, height: 788 },
            ]}
          />
        </Grid>
      </Box>
    </AdminLayout>
  );
}
