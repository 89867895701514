// src/app/utils/initializeCampaign.ts
import dayjs from "dayjs";
import { Campaign } from "../models/campaign";

export const initializeNewCampaign = (): Campaign => {
  return {
    id: "",
    title: "",
    startDate: dayjs(),
    endDate: dayjs(),
    isActive: true,
    collectUserData: 1,
    companyName: "",
    venue: "",
    description: "",
    listOrder: 0,
    customCSS: "",
    modalText: "",
    modalBg: "",
    wheel: {
      id: "",
      pageTitle: "",
      titleFromTop: 60,
      titleWidth: 48,
      titleFontSize: 60,
      motto: "",
      logo: "",
      logoFromTop: 170,
      logoHeight: 120,
      screenBg: "",
      screenCenterBg: "",
      screenCenterBgFromTop: 1300,
      screenColor: '#fff',
      textColor: '#000',
      wheelFromTop: 50,
      wheelDiameter: 900,
      colorFirst: '#fff',
      colorSecond: '#000',
      circleImage: "",
      wheelTextColor: "red",
      wheelTextColor2: "blue",
      wheelBorderColor: "#e60f0f",
      wheelPinColor: "#eeeeee",
      wheelPinImage: "",
      radiusLine: false,
      radiusLineColor: "#e60f0f",
      spinnerDecoration: true,
      spinnerImage: "",
      spinnerCover: "",
      spinnerCoverOpacity: false,
      spinnerRotate: 108.5,
      spinnerEffect: true,
      clockwise: true,
      spinDuration: 7,
      spinSpeed: 1,
      sliceAmount: 6,
      stockControlRate: null,
      probabilityDropRate: null,
      showPrizeValues: true,
      slices: Array(6).fill({
        id: "",
        prizeValue: "",
        prizeImage: "",
        wedgeImage: "",
        isPrize: true,
        probability: null,
        stock: null,
        initialStock: null,
        onStockControl: false,
        stockDropped: false,
        listOrder: null,
      }),
      campaignId: ""
    },
    formState: {
      id: "",
      formEmail: true,
      formPhone: true,
      formCompany: false,
      formGender: true,
      formContract: "",
      formAddPermission: "",
      bonusField: "",
      campaignId: ""
    },
  };
};

export const initializeCampaignData = (campaignData: Campaign): Campaign => {
  return {
    ...campaignData,
    startDate: dayjs(campaignData.startDate),
    endDate: dayjs(campaignData.endDate),
  };
};